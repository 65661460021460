import { useState } from 'react';

export function useCommonDatePicker(date) {
  const [selectedDate, setSelectedDate] = useState(date);
  const [stopDate, setStopDate] = useState('');
  const [isSelected, setIsSelected] = useState(false);

  const handleChangeDate = (newDate) => {
    setSelectedDate(newDate);
    setIsSelected(true);
  };

  const handleStopDate = (newDate) => {
    setStopDate(newDate);
  };
  return {
    isSelected,
    selectedDate,
    stopDate,
    handleStopDate,
    handleChangeDate,
  };
}
