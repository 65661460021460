export const color = {
    studio_back: '#25282f',
    white: '#ffffff',
  
    // Confin Color Definition
    // Primary
    confin_green_01: '#36BE00',
    confin_green_02: '#A6F214',
    confin_indigo: '#323842',
    confin_indigo_02: '#41444D',
    confin_black: '#26282E',
  
    // Hover
    confin_green_03: '#78E04F',
    confin_green_04: '#CDFF6C',
    // 새로추가된 그린
    confin_green_05: '#a6f21426',
  
    // Red
    confin_red_01: '#FF3B30',
    confin_red_02: '#FFA5AD',
  
    // Blue
    confin_blue: '#68E7EA',
    // 추가된
    confin_blue02: '#ecf2fc',
    confin_blue03: '#004cff',
    confin_blue04: '#C5E3FE',
  
    // Gray
    confin_gray_01: '#515C70',
    confin_gray_02: '#9FA4AA',
    confin_gray_03: '#C2C5C9',
    confin_gray_04: '#D2D2D2',
    confin_gray_05: '#ECECEC',
    confin_gray_06: '#F5F5F5',
  
    // 새로 추가된 그레이
    confin_gray_07: '#f2f2f2',
    confin_gray_08: '#f6f6f6',
    confin_gray_09: '#1d1d1d',
    confin_gray_10: '#D9D9D9',
    confin_gray_11: '#eee',
    confin_gary_12: '#999CA1',
    confin_gray_13: '#F9F9F9',
    confin_gray_14: '#A7AAB3',
  
    // Pinkv
    confin_pink_01: '#fc6ba6',
  
    // newColor
    confin_new_white: '#F9F9FA',
    confin_new_black_01: '#121316',
    confin_new_black_02: '#41444D',
    confin_new_black_03: '#000A10',
    confin_new_black_04: '#020B26',
    confin_new_blue_01: '#3565F2',
    confin_new_blue_02: '#0583F2',
    confin_new_blue_03: '#F5F7FE',
    confin_new_blue_04: '#F7FBFF',
    confin_new_blue_05: '#95AEF8',
    confin_new_blue_06: '#E4F2FF',
    confin_new_purple_01: '#96A0FF',
    confin_new_gray_01: '#707584',
    confin_new_gray_02: '#DEDFE2',
    confin_new_gray_03: '#F9F9F9',
    confin_new_gray_04: '#A7AAB3',
    confin_new_gray_05: '#E5EAF1',
  
    confin_new_red_01: '#E50914',
    confin_new_red_02: '#EA4F57',
    confin_new_red_03: '#FFEEEE',
    confin_new_green_01: '#00A980',
  };
  