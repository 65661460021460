import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import NotFound from './pages/NotFound';
import Warrant from './pages/Warrant';
import CCTV from './pages/CCTV';
import Age2 from './pages/Age2';
import Sol from './pages/Sol';

function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/age" element={<Age2 />} />

        <Route path="*" element={<NotFound />} />
        <Route path="/warrant" element={<Warrant />} />
        <Route path="/cctv" element={<CCTV />} />
        <Route path="/sol" element={<Sol />} />
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
