import { Box, Container, Grid, Stack, Typography } from '@mui/material';
// import dayjs from 'dayjs';
import React from 'react';
import CommonDatePicker from '../components/CommonDatePicker';
import { useCommonDatePicker } from '../controller/useCommonDatePicker';
import WarrantHead from '../images/warrant_head.png';
import WarrantFooter from '../images/warrant_footer.png';
import { color } from '../elements/color.js';

// 영장 시한 계산
const Warrant = () => {
  // const tomorrow = dayjs().add(1, 'day');
  const { selectedDate, handleChangeDate } = useCommonDatePicker('');
  console.log(selectedDate);
  //12시간 더한값
  const added12Hours =
    selectedDate && selectedDate.add(12, 'hour').format('YYYY.MM.DD. HH:mm');
  // 36시간을 더한 값
  const added36Hours =
    selectedDate && selectedDate.add(36, 'hour').format('YYYY.MM.DD. HH:mm');
  // 48시간을 더한 값
  const added48Hours =
    selectedDate && selectedDate.add(48, 'hour').format('YYYY.MM.DD. HH:mm');
  // 9일을 더한 값
  const added9Days =
    selectedDate && selectedDate.add(9, 'day').format('YYYY.MM.DD. HH:mm');

  const addObj = {
    add12h: {
      label1: `긴급체포승인요청`,
      label2: `(12시간 내)`,
      time: added12Hours,
    },
    add36h: {
      label1: `구속영장 신청`,
      label2: '(36시간 내)',
      time: added36Hours,
    },
    add48h: {
      label1: `구속영장 청구`,
      label2: '(48시간 내)',
      time: added48Hours,
    },
    add9d: {
      label1: `구속사건 송치`,
      label2: '(초일산입 10일)',
      time: added9Days,
    },
  };

  return (
    <Container
      sx={{
        backgroundColor: '#111',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          py: 3,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Grid container justifyContent="center" alignItems="center">
          {/* 헤더 */}
          <Grid>
            <img
              src={WarrantHead}
              alt=""
              style={{
                width: '100%',
              }}
            />
          </Grid>
          {/* 체포일시 */}
          <Grid
            item
            xs={5}
            textAlign={'center'}
            sx={{
              border: '1px solid grey',
              py: 2,
              height: 70,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              bgcolor: color.white,
            }}
          >
            <Typography fontWeight={'bold'} fontSize={16} letterSpacing={-0.8}>
              체포 일시
            </Typography>
          </Grid>
          <Grid
            item
            xs={7}
            textAlign={'center'}
            sx={{
              border: '1px solid grey',
              height: 70,

              justifyContent: 'center',
              whiteSpace: 'pre-line',
              bgcolor: color.white,
            }}
          >
            <CommonDatePicker
              viewRenderElem={['year', 'month', 'day', 'hours', 'minutes']}
              valueFormat={'YYYY.MM.DD. HH:mm'}
              selectedDate={selectedDate}
              handleChangeDate={handleChangeDate}
            />
          </Grid>
          <Grid item xs={12} sx={{ height: 8 }} />

          {Object.keys(addObj).map((k) => {
            return (
              <Grid key={k} container>
                <Grid
                  item
                  xs={5}
                  textAlign={'center'}
                  sx={{
                    border: '1px solid grey',
                    py: 2,
                    whiteSpace: 'pre-line',
                    height: 70,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    bgcolor: color.white,
                  }}
                >
                  <Stack>
                    <Typography
                      fontSize={16}
                      fontWeight={'bold'}
                      color={'#004cff'}
                      letterSpacing={-0.8}
                    >
                      {addObj[k].label1}
                    </Typography>
                    <Typography
                      fontSize={16}
                      fontWeight={'bold'}
                      letterSpacing={-0.8}
                    >
                      {addObj[k].label2}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid
                  item
                  xs={7}
                  textAlign={'center'}
                  sx={{
                    border: '1px solid grey',
                    height: 70,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    bgcolor: '#fdf8cb',
                  }}
                >
                  <Typography
                    fontWeight={'bold'}
                    fontSize={14}
                    letterSpacing={-0.8}
                  >
                    {addObj[k].time}
                  </Typography>
                </Grid>
                <Grid item xs={12} sx={{ height: 8 }} />
              </Grid>
            );
          })}
          {/* 푸터 */}
          <Grid>
            <img
              src={WarrantFooter}
              alt=""
              style={{
                width: '100%',
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default Warrant;
