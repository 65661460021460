import { Box, Container, Grid, Typography } from '@mui/material';
import React from 'react';
import CommonDatePicker from '../components/CommonDatePicker.js';
import { useCommonDatePicker } from '../controller/useCommonDatePicker.js';
import dayjs from 'dayjs';
import CurrentTimeComponent from '../components/CurrentTimeComponent.js';
import SearchCCTV from '../components/SearchCCTV.js';
import CCTVHead from '../images/cctv_head.png';
import { color } from '../elements/color.js';

function calculateWithoutMilliseconds(stopDate) {
  // SSS 값이 있는 경우, 내림 처리 또는 제외하고 계산
  return stopDate.split('.')[0];
}

//CCTV 페이지
const Page = () => {
  const now = dayjs();
  const {
    isSelected,
    selectedDate,
    stopDate,
    handleStopDate,
    handleChangeDate,
  } = useCommonDatePicker('');
  console.log('stopDate', dayjs(stopDate).format('YYYY-MM-DD HH:mm:ss'));
  console.log(
    'selectedDate',
    dayjs(selectedDate).format('YYYY-MM-DD HH:mm:ss')
  );
  // 차이를 계산하고 표시하는 함수
  const calculateTimeDifference = () => {
    // 신규로직
    const formattedSelectedDate = dayjs(selectedDate).format(
      'YYYY-MM-DD HH:mm:ss'
    );
    const formattedStopedDate = calculateWithoutMilliseconds(
      dayjs(stopDate).format('YYYY-MM-DD HH:mm:ss.SSS')
    );
    const differenceInSeconds = dayjs(formattedSelectedDate).diff(
      formattedStopedDate,
      'second'
    );
    // console.log({
    //   formattedSelectedDate,
    //   formattedStopedDate,
    //   differenceInSeconds,
    // });

    //신규로직1

    // const differenceInSeconds = selectedDate.diff(stopDate, 'second');
    const absoluteDifferenceInSeconds = Math.abs(differenceInSeconds);
    const result = {
      differenceInSeconds,
      absoluteDifferenceInSeconds,
    };
    console.log({
      selectedDate: dayjs(selectedDate).format('YYYY-MM-DD HH:mm:ss'),
      differenceInSeconds,
      absoluteDifferenceInSeconds,
    });
    const seconds = absoluteDifferenceInSeconds % 60;
    const minutes = Math.floor(absoluteDifferenceInSeconds / 60) % 60;
    const hours = Math.floor(absoluteDifferenceInSeconds / 3600);
    const days = Math.floor(absoluteDifferenceInSeconds / (3600 * 24)); // 일 수 계산

    // 일(day)을 제외한 시간, 분, 초를 시간에 담기
    const remainingHours = Math.floor(hours % 24);
    const remainingMinutes = minutes;
    const remainingSeconds = seconds;

    let text = '';

    if (differenceInSeconds === 0) {
      text = '오차없음';
    } else if (differenceInSeconds > 0) {
      text = `현재보다 `;
      if (days > 0) {
        text += `${days}일 `;
      }
      if (remainingHours > 0) {
        text += `${remainingHours}시간 `;
      }
      if (remainingMinutes > 0) {
        text += `${remainingMinutes}분 `;
      }
      if (remainingSeconds > 0) {
        text += `${remainingSeconds}초 `;
      }
      text += '빠름';
    } else {
      text = `현재보다 `;
      if (days > 0) {
        text += `${days}일 `;
      }
      if (remainingHours > 0) {
        text += `${remainingHours}시간 `;
      }
      if (remainingMinutes > 0) {
        text += `${remainingMinutes}분 `;
      }
      if (remainingSeconds > 0) {
        text += `${remainingSeconds}초 `;
      }
      text += '느림';
    }

    return {
      ...result,
      text,
    };
  };

  // 12시간을 더한 값

  // const formattedTime = new Date().toLocaleString('en-US', {
  //   year: 'numeric',
  //   month: '2-digit',
  //   day: '2-digit',
  //   hour: '2-digit',
  //   minute: '2-digit',
  //   second: '2-digit',
  //   hour12: false,
  // });
  return (
    <Container
      sx={{
        backgroundColor: '#111',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          py: 3,
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <Grid container justifyContent="center" alignItems="center">
          {/* 헤더 */}
          <Grid>
            <img
              src={CCTVHead}
              alt=""
              style={{
                width: '100%',
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              sx={{
                color: color.white,
                px: 1,
                my: 1,
                fontWeight: 'bold',
                textAlign: 'center',
                width: 60,
                bgcolor: '#515C70',
                borderRadius: 5,
              }}
            >
              오차계산
            </Typography>
          </Grid>

          {/* 현재시간 */}
          <Grid
            item
            xs={5}
            textAlign={'center'}
            sx={{
              border: '1px solid grey',
              py: 2,
              height: 70,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              bgcolor: color.white,
            }}
          >
            <Typography fontWeight={'bold'} fontSize={18} letterSpacing={-0.8}>
              현재 시간
            </Typography>
          </Grid>
          <Grid
            item
            xs={7}
            textAlign={'center'}
            sx={{
              border: '1px solid grey',
              height: 70,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              whiteSpace: 'pre-line',
              bgcolor: color.white,
            }}
          >
            <CurrentTimeComponent
              today={now}
              handleStopDate={handleStopDate}
              isSelected={isSelected}
            />
          </Grid>
          <Grid item xs={12} sx={{ height: 8 }} />

          {/* CCTV시간 */}
          <Grid
            item
            xs={5}
            textAlign={'center'}
            sx={{
              border: '1px solid grey',
              py: 2,
              height: 70,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              bgcolor: color.white,
            }}
          >
            <Typography fontWeight={'bold'} fontSize={18} letterSpacing={-0.8}>
              CCTV 시간
            </Typography>
          </Grid>
          <Grid
            id="s"
            item
            xs={7}
            textAlign={'center'}
            sx={{
              border: '1px solid grey',
              height: 70,
              justifyContent: 'center',
              whiteSpace: 'pre-line',
              bgcolor: color.white,
            }}
          >
            <Box sx={{ width: '100%' }}>
              <CommonDatePicker
                viewMode="cctv"
                selectedDate={selectedDate}
                handleChangeDate={handleChangeDate}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ height: 8 }} />

          {/* 오차시간 */}
          <Grid
            item
            xs={5}
            textAlign={'center'}
            sx={{
              border: '1px solid grey',
              py: 2,
              height: 70,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              bgcolor: color.white,
            }}
          >
            <Typography
              fontSize={18}
              fontWeight={'bold'}
              letterSpacing={-0.8}
              color={'blue'}
            >
              오차 시간
            </Typography>
          </Grid>
          <Grid
            item
            xs={7}
            textAlign={'center'}
            sx={{
              border: '1px solid grey',
              height: 70,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              bgcolor: '#fdf8cb',
            }}
          >
            <Typography
              fontWeight={'bold'}
              fontSize={14}
              letterSpacing={-0.8}
              gutterBottom
            >
              {selectedDate && calculateTimeDifference().text}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography
            sx={{
              color: color.white,

              px: 1,
              my: 1,
              fontWeight: 'bold',
              textAlign: 'center',
              width: 130,
              bgcolor: '#515C70',
              borderRadius: 5,
            }}
          >
            CCTV 시간대 검색
          </Typography>
        </Grid>
        <SearchCCTV
          text1Label={'찾는 시간'}
          text2Label={'CCTV 시간'}
          caculateMode={'add'}
          differenceInSeconds={
            selectedDate ? calculateTimeDifference().differenceInSeconds : ''
          }
        />
        <Grid item xs={12}>
          <Typography
            sx={{
              color: color.white,
              px: 1,
              my: 1,
              fontWeight: 'bold',
              textAlign: 'center',
              width: 130,
              bgcolor: '#515C70',
              borderRadius: 5,
            }}
          >
            발생 시간대 검색
          </Typography>
        </Grid>
        <SearchCCTV
          text1Label={'CCTV 시간'}
          text2Label={'발생 시간'}
          caculateMode={'subtract'}
          differenceInSeconds={
            selectedDate ? calculateTimeDifference().differenceInSeconds : ''
          }
        />
      </Box>
    </Container>
  );
};

export default Page;
