import { Typography } from '@mui/material';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';

const CurrentTimeComponent = ({ today, isSelected, handleStopDate }) => {
  const [currentTime, setCurrentTime] = useState(today);
  useEffect(() => {
    let intervalId;
    const now = dayjs();
    // 1초마다 현재 시간을 업데이트하는 함수를 생성
    if (!isSelected) {
      const updateClock = () => {
        setCurrentTime(now);
      };

      // // 맨 처음 컴포넌트가 마운트될 때 타이머를 시작하고,
      // // 컴포넌트가 언마운트될 때 타이머를 정리합니다.
      // // 컴포넌트가 마운트될 때 타이머 시작

      intervalId = setInterval(updateClock, 1000);
    }

    if (isSelected) handleStopDate(currentTime);

    // 컴포넌트가 언마운트될 때 타이머 정리
    return () => {
      clearInterval(intervalId);
    };
    //eslint-disable-next-line
  }, [isSelected, currentTime]);
  // 시간을 시:분:초 형식으로 포맷합니다.

  return (
    <Typography
      fontWeight={'bold'}
      gutterBottom
      fontSize={14}
      letterSpacing={-0.8}
    >
      {currentTime.format('YYYY.MM.DD. HH:mm:ss')}
    </Typography>
  );
};

export default CurrentTimeComponent;
