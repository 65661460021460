import {
    Box,
    Container,
    Grid,
    Stack,
    TextField,
    Typography,
  } from '@mui/material';
  import React, { useState } from 'react';
  import AgeHead from '../images/age_head.png';
  import { color } from '../elements/color.js';
  
  const defaultText = [
    { id: '촉법소년', text: '촉법소년 (만 10세 ~ 만 13세)' },
    { id: '범죄소년', text: '범죄소년 (만 14세 ~ 만 18세)' },
    { id: '청소년보호법', text: '청소년 보호법 (연 19세 미만)' },
    {
      id: '찜질방',
      text: '※ 찜질방 22시~05시 출입불가 (공중위생관리법)',
    },
    { id: '아동복지법', text: '아동복지법상 아동 (만 18세 미만)' },
    { id: '게임음악산업진흥법', text: '게임·음악산업진흥법 (만 19세 미만)' },
    { id: '노래방', text: '※ 노래방, PC방 22시~09시 출입불가' },
    { id: '경범죄', text: '경범죄처벌법 (만 18세 미만: 통고처분 금지)' },
  ];
  
  const isValidDate = (input) => {
    const isNumeric = /^\d+$/.test(input);
    // 입력값이 6자리의 숫자인지 확인
  
    if (!isNumeric || input.toString().length !== 6) {
      return false;
    }
  
    // 6자리 숫자를 문자열로 변환
    const inputString = input.toString();
  
    // 문자열을 2자리씩 자르기
    const year = inputString.slice(0, 2);
    const month = inputString.slice(2, 4);
    const day = inputString.slice(4, 6);
  
    // 입력값 유효성 검사
    const isValidYear =
      /^\d{2}$/.test(year) && Number(year) >= 0 && Number(year) <= 99;
    const isValidMonth =
      /^\d{2}$/.test(month) && Number(month) >= 1 && Number(month) <= 12;
    const isValidDay =
      /^\d{2}$/.test(day) && Number(day) >= 1 && Number(day) <= 31;
  
    if (isValidYear && isValidMonth && isValidDay) {
      return true;
    } else {
      return false;
    }
  };
  
  const formatBirthDate = (birthDate) => {
    // 현재 연도의 마지막 두 자리 가져오기
    const currentYearLastTwoDigits = new Date().getFullYear() % 100;
  
    // 생년월일 문자열에서 연도 부분 추출
    const birthYear = parseInt(birthDate.slice(0, 2), 10);
  
    // 연도에 따라 2000년대 또는 1900년대로 처리
    const formattedYear =
      birthYear <= currentYearLastTwoDigits ? 2000 + birthYear : 1900 + birthYear;
  
    // 생년월일 문자열에서 월과 일 부분 추출
    const month = birthDate.slice(2, 4);
    const day = birthDate.slice(4, 6);
  
    // 포맷팅된 날짜 문자열 반환
    return `${formattedYear}-${month}-${day}`;
  };
  
  const getAgeStatus = (birthDate) => {
    let resObj = { color: 'black', textList: [] };
    if (!birthDate) return { ...resObj, color: 'red', text: '값을입력해주세요' };
    // formatBirthDate 함수를 사용하여 생년월일을 포맷팅
    const formattedBirthDate = formatBirthDate(birthDate);
    // 현재 날짜
    const currentDate = new Date();
  
    // 생년월일을 Date 객체로 변환
    const birthDateObj = new Date(formattedBirthDate);
  
    // 만 나이 계산 (생일이 지났으면 1살 추가)
    const ageDiffMs = currentDate - birthDateObj;
    const ageDate = new Date(ageDiffMs);
    const age = ageDate.getUTCFullYear() - 1970;
  
    // 연 나이 계산 (현재년도의 1월 1일을 기준으로)
    const currentYear = currentDate.getFullYear();
    const ageOnJanuary1 = currentYear - birthDateObj.getFullYear();
  
    resObj = {
      ...resObj,
      age,
      ageOnJanuary1,
    };
    if (!isValidDate(birthDate)) {
      return { ...resObj, color: 'red' };
    }
    // 나이 범위에 따라 리턴값 결정
    if (age >= 10 && age <= 13) {
      resObj = {
        ...resObj,
        textList: [
          ...resObj.textList,
          { id: '촉법소년', text: '촉법소년 (만 10세 ~ 만 13세)' },
        ],
      };
    }
    if (age >= 14 && age <= 18) {
      resObj = {
        ...resObj,
        textList: [
          ...resObj.textList,
          { id: '범죄소년', text: '범죄소년 (만 14세 ~ 만 18세)' },
        ],
      };
    }
    if (ageOnJanuary1 < 19) {
      resObj = {
        ...resObj,
        textList: [
          ...resObj.textList,
          { id: '청소년보호법', text: '청소년 보호법 (연 19세 미만)' },
          {
            id: '찜질방',
            text: '※ 찜질방 22시~05시 출입불가 (공중위생관리법)',
          },
          {
            id: '게임음악산업진흥법',
            text: '게임·음악산업진흥법 (만 19세 미만)',
          },
          { id: '노래방', text: '※ 노래방, PC방 22시~09시 출입불가' },
        ],
      };
    }
    if (ageOnJanuary1 < 18) {
      resObj = {
        ...resObj,
        textList: [
          ...resObj.textList,
          { id: '아동복지법', text: '아동복지법상 아동 (만 18세 미만)' },
          { id: '경범죄', text: '경범죄처벌법 (만 18세 미만: 통고처분 금지)' },
        ],
      };
    }
  
    if (ageOnJanuary1 >= 19) {
      resObj = {
        ...resObj,
        textList: [...resObj.textList, { id: '성인', text: '제약사항 없음' }],
      };
    }
    return resObj;
  };
  
  //미성년자 확인
  const Age = () => {
    const [birthDate, setBirthDate] = useState('');
    const [error, setError] = useState(true);
  
    const handleChangeDate = (e) => {
      const newValue = e.target.value;
      // 숫자만 6자리까지 입력 가능하도록 설정
      if (/^\d{0,6}$/.test(newValue)) {
        setBirthDate(newValue);
        isValidDate(newValue) ? setError(false) : setError(true); // 에러 제거
      }
    };
  
    console.log(getAgeStatus(birthDate).textList);
    return (
      <Container
        // disableGutters
        sx={{
          backgroundColor: '#111',
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            py: 3,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Grid
            container
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ bgcolor: '#111' }}
          >
            {/* 헤더 */}
            <Grid>
              <img
                src={AgeHead}
                alt=""
                style={{
                  width: '100%',
                }}
              />
            </Grid>
            {/* 생년월일 */}
            <Grid
              item
              xs={5}
              textAlign={'center'}
              sx={{
                border: '1px solid grey',
                py: 2,
                height: 70,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                bgcolor: color.white,
              }}
            >
              <Typography fontWeight={'bold'}>{`생년월일`}</Typography>
            </Grid>
            <Grid
              item
              xs={7}
              textAlign={'center'}
              sx={{
                border: '1px solid grey',
                height: 70,
                justifyContent: 'center',
                whiteSpace: 'pre-line',
                bgcolor: '#4181a1',
              }}
            >
              <TextField
                fullWidth
                value={birthDate}
                error={error}
                placeholder={'생년월일 6자리 입력'}
                sx={{
                  backgroundColor: color.white,
                  border: 'none',
                  width: '100%',
                  height: '100%',
  
                  '& .MuiOutlinedInput-root': {
                    borderRadius: 0,
                  },
                  '.MuiSelect-iconOutlined': {
                    fill: color?.white,
                  },
                  '.MuiOutlinedInput-notchedOutline': {
                    borderColor: 'transparent',
                    borderRadius: '0 !important',
                    borderWidth: '0.3rem', // 최초의 포커스 상태에서의 아웃라인 굵기
                    borderTopWidth: '0.25rem',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: color?.white,
  
                    color: color?.white,
                  },
  
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: color?.white,
                    borderWidth: '0.15rem',
                  },
                  '& .MuiInputBase-input': {
                    fontSize: '14px', // 원하는 글씨 크기로 설정
  
                    fontWeight: 'bold',
                  },
                }}
                inputProps={{
                  style: {
                    height: '36px',
                    textAlign: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                  },
                  inputMode: 'numeric', // 숫자 키패드 보이도록 설정
                  pattern: '[0-9]*', // 숫자만 입력 가능하도록 설정
                }}
                onChange={handleChangeDate}
              />
            </Grid>
            <Grid item xs={12} sx={{ height: 8 }} />
  
            {/* 나이계산 */}
            <Grid
              item
              xs={5}
              textAlign={'center'}
              sx={{
                border: '1px solid grey',
                py: 2,
                height: 70,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                bgcolor: color.white,
              }}
            >
              <Typography
                fontWeight={'bold'}
                color={'blue'}
                letterSpacing={-0.8}
                gutterBottom
              >
                만 나이 / 연 나이
              </Typography>
            </Grid>
            <Grid
              item
              xs={7}
              textAlign={'center'}
              sx={{
                border: '1px solid grey',
                py: 2,
                height: 70,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                bgcolor: '#fdf8cb',
              }}
            >
              <Typography
                fontWeight={'bold'}
                letterSpacing={-0.8}
                gutterBottom
                color={getAgeStatus(birthDate).color}
              >
                {!isValidDate(birthDate)
                  ? ''
                  : `만 ${getAgeStatus(birthDate).age}세/연 ${
                      getAgeStatus(birthDate).ageOnJanuary1
                    }세`}
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ height: 8 }} />
            {/* 참고사항 */}
            <Grid
              item
              xs={12}
              textAlign={'center'}
              sx={{
                border: '1px solid grey',
              }}
            >
              <Stack height={'100%'}>
                <Box bgcolor={'#eee'}>
                  <Typography
                    py={1}
                    fontWeight={'bold'}
                    color={color.white}
                    bgcolor={'#4181a1'}
                  >
                    제약사항
                  </Typography>
  
                  <Typography
                    bgcolor={'#fdf8cb'}
                    whiteSpace={'pre-line'}
                    textAlign={'left'}
                    px={1}
                    letterSpacing={-0.8}
                    py={3}
                  >
                    {!isValidDate(birthDate)
                      ? defaultText.map((v, idx) => {
                          return (
                            <Typography
                              key={v.id}
                              fontSize={
                                ['찜질방', '노래방'].includes(v.id) ? 14 : 16
                              }
                            >{`${v.text}\n`}</Typography>
                          );
                        })
                      : getAgeStatus(birthDate).textList.map((v, idx) => {
                          return (
                            <Typography
                              key={v.id}
                              fontSize={
                                ['찜질방', '노래방'].includes(v.id) ? 14 : 16
                              }
                            >{`${v.text}\n`}</Typography>
                          );
                        })}
                  </Typography>
                </Box>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Container>
    );
  };
  
  export default Age;
  