import { Box, Container } from '@mui/material';

import { color } from '../elements/color.js';

const CopsPageContainer = (props) => {
  const { backgroundColor = color?.white, pageCenter, sx } = props;

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          backgroundColor: backgroundColor,
          display: pageCenter && 'flex',
          justifyContent: pageCenter && 'center',
          alignItems: pageCenter && 'center',
          ...sx,
        }}
      >
        <Container disableGutters>{props.children}</Container>
      </Box>
    </Box>
  );
};

export default CopsPageContainer;
