import React from 'react';
import 'dayjs/locale/ko';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DateTimePicker, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import { color } from '../elements/color';
import dayjs from 'dayjs';

const CommonDatePicker = ({
  mode = 'time',
  viewMode = 'normal',
  viewRenderElem = ['year', 'month', 'day', 'hours', 'minutes', 'seconds'],
  valueFormat = 'YYYY.MM.DD. HH:mm:ss',
  selectedDate,
  handleChangeDate,
  label = '날짜를 입력해주세요',
}) => {
  // 커스텀 훅 사용
  const onAnyTextFieldChanged = (e) => {
    if (!!e?.preventDefault) {
      e?.preventDefault();
      e?.stopPropagation();
    }
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ko">
      {mode === 'birth' ? (
        <>
          <DatePicker
            value={selectedDate}
            onChange={handleChangeDate}
            sx={{
              border: 'none',
              width: '100%',
              '.MuiSelect-iconOutlined': {
                fill: color?.white,
              },
              '.MuiOutlinedInput-notchedOutline': {
                borderColor: 'transparent',
                borderRadius: '0 !important',
                borderWidth: '0.3rem', // 최초의 포커스 상태에서의 아웃라인 굵기
                borderTopWidth: '0.25rem', // 최초의 포커스 상태에서의 아웃라인 굵기
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: color?.white,

                color: color?.white,
              },

              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: color?.white,
                borderWidth: '0.15rem',
              },
              '& .MuiInputBase-input': {
                fontSize: '14px', // 원하는 글씨 크기로 설정
                fontWeight: 'bold',
              },
            }}
            slotProps={{
              textField: {
                onBeforeInput: onAnyTextFieldChanged,

                InputProps: {
                  readOnly: true,
                  // sx: { bgcolor: '#111', height: '100%' },
                  value: !selectedDate
                    ? '이 곳에 입력하세요'
                    : dayjs(selectedDate).format('YYYY.MM.DD.'),
                  sx: {
                    color: !selectedDate ? color.confin_gary_12 : 'blue',
                    py: viewMode === 'cctv' ? 2.5 : 1,
                    px: !selectedDate ? 0 : 4.5,
                  },
                  inputProps: {
                    style: { textAlign: 'center' },
                  },
                },
                // variant: 'standard',
              },
            }}
          />
        </>
      ) : (
        <>
          <DateTimePicker
            ampm={false}
            value={selectedDate} // 선택한 값을 설정
            views={viewRenderElem}
            onChange={handleChangeDate}
            sx={{
              border: 'none',
              width: '100%',
              '.MuiSelect-iconOutlined': {
                fill: color?.white,
              },
              '.MuiOutlinedInput-notchedOutline': {
                borderColor: 'transparent',
                borderRadius: '0 !important',
                borderWidth: '0.3rem', // 최초의 포커스 상태에서의 아웃라인 굵기
                borderTopWidth: '0.25rem',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: color?.white,
                color: color?.white,
              },

              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: color?.white,
                borderWidth: '0.15rem',
              },
              '& .MuiInputBase-input': {
                fontSize: '14px', // 원하는 글씨 크기로 설정
                fontWeight: 'bold',
              },
            }}
            slotProps={{
              textField: {
                onBeforeInput: onAnyTextFieldChanged,

                InputProps: {
                  readOnly: true,
                  // sx: { bgcolor: '#111', height: '100%' },
                  value: !selectedDate
                    ? '이 곳에 입력하세요'
                    : dayjs(selectedDate).format(valueFormat),
                  sx: {
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: color?.white,
                      color: color?.white,
                    },
                    color: !selectedDate ? color.confin_gary_12 : 'blue',
                    py: 1,
                    px: !selectedDate ? 3 : viewMode === 'cctv' ? 1 : 2,
                    letterSpacing: -0.8,
                  },
                  inputProps: {
                    style: { textAlign: 'center' },
                  },
                },
                // variant: 'standard',
              },
            }}
            viewRenderers={{
              hours: renderTimeViewClock,
              minutes: renderTimeViewClock,
              seconds: renderTimeViewClock,
            }}
          />
        </>
      )}
    </LocalizationProvider>
  );
};

export default CommonDatePicker;
