import {
    Autocomplete,
    Box,
    Container,
    Grid,
    Stack,
    TextField,
    Typography,
  } from '@mui/material';
  import React, { useState } from 'react';
  import CommonDatePicker from '../components/CommonDatePicker';
  import SearchIcon from '@mui/icons-material/Search';
  import { useCommonDatePicker } from '../controller/useCommonDatePicker';
  import initailData from '../components/sol.json';
  import SolHead from '../images/sol_head.png';
  import SolFooter from '../images/sol_footer.png';
  import { color } from '../elements/color.js';
  //공소시효 페이지
  const Sol = () => {
    const parseOptionList = Object.keys(initailData.시트1);
    const { selectedDate, handleChangeDate } = useCommonDatePicker('');
    const [jsonResults, setJsonResults] = useState('이 곳에 입력하세요');
  
    const calculateYear = (selectedDate, value) => {
      if (!selectedDate || !value) return '';
  
      let result = '';
  
      switch (value) {
        case '25년':
          result = selectedDate
            .add(25, 'year')
            .subtract(1, 'day')
            .format('YYYY.MM.DD.');
          // result = `${year + 25}. ${month}. ${day}.`;
          break;
        case '7년':
          result = selectedDate
            .add(7, 'year')
            .subtract(1, 'day')
            .format('YYYY.MM.DD.');
          break;
        case '10년':
          result = selectedDate
            .add(10, 'year')
            .subtract(1, 'day')
            .format('YYYY.MM.DD.');
          break;
        case '15년':
          result = selectedDate
            .add(15, 'year')
            .subtract(1, 'day')
            .format('YYYY.MM.DD.');
          break;
        case '5년':
          result = selectedDate
            .add(5, 'year')
            .subtract(1, 'day')
            .format('YYYY.MM.DD.');
          break;
        case '적용배제':
          result = '적용배제';
          break;
        default:
          result = '사안별 직접 계산할 것';
      }
  
      return result;
    };
  
    return (
      <Container
        sx={{
          backgroundColor: '#111',
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            py: 3,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Grid
            container
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ bgcolor: '#111' }}
          >
            {/* 헤더 */}
            <Grid>
              <img
                src={SolHead}
                alt=""
                style={{
                  width: '100%',
                }}
              />
            </Grid>
  
            {/* 범행종료일 */}
            <Grid
              item
              xs={5}
              textAlign={'center'}
              sx={{
                border: '1px solid grey',
                py: 2,
                height: 70,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                bgcolor: color.white,
              }}
            >
              <Typography fontWeight={'bold'} fontSize={16} letterSpacing={-0.8}>
                {`범행 종료일`}
              </Typography>
            </Grid>
            <Grid
              item
              xs={7}
              textAlign={'center'}
              sx={{
                border: '1px solid grey',
                height: 70,
                justifyContent: 'center',
                whiteSpace: 'pre-line',
                bgcolor: color.white,
              }}
            >
              <Box sx={{ width: '100%' }}>
                <CommonDatePicker
                  mode="birth"
                  selectedDate={selectedDate}
                  handleChangeDate={handleChangeDate}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sx={{ height: 8 }} />
            {/* 죄명검색 */}
            <Grid
              item
              xs={5}
              textAlign={'center'}
              sx={{
                border: '1px solid grey',
                height: 65,
                mb: jsonResults === '이 곳에 입력하세요' ? 0.8 : 0.8,
                bgcolor: color.white,
              }}
            >
              <Stack sx={{ flexGrow: 1 }}>
                <Typography
                  fontSize={16}
                  fontWeight={'bold'}
                  letterSpacing={-0.8}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: 35,
                  }}
                >
                  죄명 검색
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: 35,
                    backgroundColor: '#eee',
                  }}
                >
                  <SearchIcon />
                  <Typography
                    fontWeight={'bold'}
                    fontSize={12}
                    letterSpacing={-0.8}
                  >{`대검 예규 - 죄명 표기`}</Typography>
                </Box>
              </Stack>
            </Grid>
            <Grid
              id="s"
              item
              xs={7}
              textAlign={'center'}
              sx={{
                border: '1px solid grey',
                whiteSpace: 'pre-line',
              }}
            >
              <Autocomplete
                options={parseOptionList}
                value={jsonResults}
                onChange={(event, newValue) => {
                  setJsonResults(newValue);
                }}
                sx={{
                  width: '98', // Autocomplete의 폭을 100%로 설정
                  bgcolor: color.white,
                  // borderRadius: 2,
                }}
                renderInput={(params) => (
                  <TextField
                    sx={{
                      height: '100%', // 부모 Grid의 높이와 동일하게 설정
                      '.MuiSelect-iconOutlined': {
                        fill: color?.white,
                      },
                      // '.MuiOutlinedInput-root': {
                      //   '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      //     borderColor: 'red',
                      //     color: color?.white,
                      //   },
                      //   '&:hover .MuiOutlinedInput-notchedOutline': {
                      //     borderColor: 'red',
                      //   },
                      // },
                      '.MuiOutlinedInput-notchedOutline': {
                        borderColor:
                          Boolean(jsonResults === '이 곳에 입력하세요') &&
                          '#ff3b2f',
                        borderWidth:
                          Boolean(jsonResults === '이 곳에 입력하세요') &&
                          '0.3rem',
                        borderRadius: '0 !important',
                        borderTopWidth: '0.25rem', // 최초의 포커스 상태에서의 아웃라인 굵기
                      },
                      '& .MuiInputBase-input': {
                        fontSize: '14px',
                        fontWeight: 'bold',
                        height: Boolean(jsonResults === '이 곳에 입력하세요')
                          ? '36px'
                          : '37px',
                        color: Boolean(jsonResults === '이 곳에 입력하세요')
                          ? color?.confin_gary_12
                          : 'blue',
                      },
  
                      // '.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline':
                      //   {
                      //     borderColor: 'red', // TextField가 hover 시 테두리 색상을 빨간색으로 변경
                      //   },
  
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'red',
                        // height: '80px',
                        borderWidth: '0.4rem',
                      },
                    }}
                    // inputProps={{ style:{},value: 'sss' }}
                    {...params}
                  />
                )}
              />
            </Grid>
  
            <Grid item xs={12} sx={{ height: 8 }} />
            {/* 공소시효기간 */}
            <Grid
              item
              xs={5}
              textAlign={'center'}
              sx={{
                border: '1px solid grey',
                height: 70,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                bgcolor: color.white,
              }}
            >
              <Typography
                fontSize={16}
                fontWeight={'bold'}
                letterSpacing={-0.8}
                color={'blue'}
              >
                공소시효 기간
              </Typography>
            </Grid>
            <Grid
              item
              xs={7}
              textAlign={'center'}
              sx={{
                border: '1px solid grey',
                height: 70,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                bgcolor: '#fdf8cb',
              }}
            >
              <Typography fontWeight={'bold'} letterSpacing={-0.8} fontSize={16}>
                {initailData.시트1?.[jsonResults]?.공소시효 || ''}
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ height: 8 }} />
            {/* 공소시효만료일 */}
            <Grid
              item
              xs={5}
              textAlign={'center'}
              sx={{
                border: '1px solid grey',
                height: 70,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                bgcolor: color.white,
              }}
            >
              <Typography
                fontSize={16}
                fontWeight={'bold'}
                letterSpacing={-0.8}
                color={'blue'}
              >
                공소시효 만료일
              </Typography>
            </Grid>
            <Grid
              item
              xs={7}
              textAlign={'center'}
              sx={{
                border: '1px solid grey',
                height: 70,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                bgcolor: '#fdf8cb',
              }}
            >
              <Typography fontWeight={'bold'} letterSpacing={-0.8} fontSize={16}>
                {calculateYear(
                  selectedDate,
                  initailData.시트1?.[jsonResults]?.공소시효
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ height: 8 }} />
            {/* 참고사항 */}
  
            <Grid>
              <img
                src={SolFooter}
                alt=""
                style={{
                  width: '100%',
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </Container>
    );
  };
  
  export default Sol;
  